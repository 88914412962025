<template>
 <div>
  <activityListNormal v-if="!$store.state.user.elderModel" /> 
  <activityListElder v-else/>

 </div>
</template>

<script>
import activityListNormal from './activity-list-normal.vue'
import activityListElder from './activity-list-elder.vue'
 export default {
   name: '',
   components: {
activityListNormal,
activityListElder
   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>
